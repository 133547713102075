@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/_mixins";

.header {
    @include make-col(6);

    @include media-breakpoint-down(md, $grid-breakpoints) {
        @include make-col(12);
    }
}
