@import "../../../theme/variables";

.container {
  .specialistName {
    font-weight: bold;
    font-size: 1rem;
    min-width: 150px;
    color: var(--carbon);
  }

  .specialistSpeciality {
    font-size: 0.7rem;
    color: var(--carbon);
  }

  .location {
    font-size: 0.7rem;
    color: var(--carbon);
  }

  .time {
    font-weight: bold;
    font-size: 1rem;
    color: var(--carbon);
  }

  .action {
    border-radius: 10px;
    border: none;
  }

  .weekDay {
    min-width: 75px;
    font-weight: bold;
    font-size: 0.8rem;
    color: var(--carbon);
  }

  .specialistImage {
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  .profile {
    cursor: pointer;
    min-width: 100px;
    display: flex;
    gap: 2rem;
    align-items: center;
    
    .appointmentType {
      font-weight: bold;
      font-size: 0.7rem;
      color: var(--carbon);
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .appointmentTypeImg {
        height: 20px;
        margin-right: 5px;
      }
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
    }
  }

  .noTimes{
    min-height: 2.8rem !important;
    height: 2.8rem !important;
  }

  .controlPanel {
    position: relative;
    min-height: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    &[data-can-show-more="false"] {
      display: none;
    }
    ._absolute_center {
      position: absolute;
      transform-origin: center;
      left: 50%;
      top: 50%;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .showMore {
      @extend ._absolute_center;
      text-align: center;
      color: var(--primary) !important;
      font-weight: bold;
      text-transform: uppercase;
      &:hover,
      &:focus {
        background-color: unset !important;
        color: var(--primary-hover) !important;
      }
    }

    .loading {
      display: block !important;
      margin: 0 auto;
      padding: 1rem 0;

      .spinnerBackground {
        @extend ._absolute_center;
        border: 2px solid white;
        animation: spin 4s infinite linear;
      }

      .spinnerCircle {
        @extend ._absolute_center;
        border: 2px solid var(--primary);
        border-bottom-color: transparent;
        animation: spin 0.8s infinite linear;
      }

      @keyframes spin {
        from {
          transform: translateX(-50%) translateY(-50%) rotate(0deg);
        }

        to {
          transform: translateX(-50%) translateY(-50%) rotate(360deg);
        }
      }
    }
  }
}

.listItemContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  border-top: 1px solid black;
  border-bottom: 1px solid transparent;

  .mobileListItemDiv {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    grid-column: 1 / span 12;

    .profileDiv {
      grid-column: 1 / span 4;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .profileDiv2 {
      grid-column: 5 / span 8;
    }
    
    .mobileTimeDiv {
      grid-column: 1 / span 4;
      display: flex;
      flex-direction: column;
      margin: auto;

      .mobileTimeInnerDiv {
        text-align: start;
      }
    }
    
    .mobileButtonDiv {
      grid-column: 5 / span 8;
      
      button {
        width: 100%;
      }
      
    }

  }

  &[data-is-last-item-in-visible-list="true"] {
    border-bottom: 1px solid black;
  }

  @media (min-width: $break-md) {
    grid-template-columns: auto auto auto 1fr auto;
    gap: 2rem;
    .profile {
      align-items: center;

      .location {
        display: none;
      }
    }
  }

  .profile {
    grid-column: 1 / span 2;
    @media (min-width: $break-md) {
      grid-column: unset;
    }
    
  }
  
  .profileDiv {
    width: 260px;
    
    @media(max-width: $break-md){
      display: inherit;
      width: auto;
      
    }
    
  }

  .duration {
    font-size: 0.8rem;
      font-weight: bold;
      color: var(--carbon);
    
    @media(max-width: $break-md){
        padding-left: 57px;
      }
  }
  

  .appointmentType {
    font-weight: bold;
    font-size: 0.7rem;
    color: var(--carbon);

    .appointmentTypeImg {
      height: 20px;
      margin-right: 5px;
    }
  }
  
  .timeAndDuration {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 0.7rem;
    color: var(--carbon);
    gap: 0.3rem;

    & > div:last-child {
      font-weight: normal;
    }
    
  }
  
}
