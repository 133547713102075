.cancelAppointmentModal {

  >div{
    max-width: 700px;

    >div{
      padding: 1rem;
    }
  }
  .confirmContainer {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }
}