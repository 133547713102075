@import "../../../theme/variables";
.inputWithPrependInsideInput {
  position: relative;
  :global(.input-group-prepend) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  input {
    padding-left: 2rem;
  }
}

.HomePageHeader {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  padding: calc(var(--fenix-navbar-height) + 6rem) 0;
  @media (min-width: $break-lg) {
    padding: calc(var(--fenix-navbar-height) + 6rem) 0;
  }

  .pageHeaderWelcomeText {
    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: 1 / span 12;
    }
    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }

    color: black;
    font-family: var(--fenix-font-family-medium);
    font-size: clamp(2rem, 4vw, 3rem);
  }

  .content {
    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: 1 / span 12;
    }
    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }
    background-color: rgba(255, 255, 255, 0.4) !important;
    padding: 1rem 1.1rem;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .actionButton {
      padding: 1rem;
      background-color: var(--primary);
      color: var(--light);
      font-family: var(--fenix-font-family-semibold);
      border-radius: var(--border-radious-md, 0.75rem);
      font-size: 1.1rem !important;
      border: unset;
      justify-content: flex-start;

      &:hover,
      &:focus,
      &:focus-within {
        background-color: var(--primary-hover) !important;
        color: white !important;
      }

      & > span {
        white-space: pre-wrap;
        text-align: left;
        @media (min-width: $break-lg) {
          white-space: unset;
        }
      }

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 0;
      }
    }

    .buttonsContainer {
      display: inline-flex;
      flex-direction: column;
      gap: 0.6rem;
      @media (min-width: $break-lg) {
        gap: 1rem;
      }
      @media (min-width: $break-lg) {
        flex-direction: row;
      }
    }

    .inputsContainer {
      display: inline-flex;
      background-color: var(--light);
      gap: 0.5rem;
      padding: 0.5rem;
      border-radius: var(--border-radious-md, 0.75rem);
      flex-direction: column;

      @media (min-width: $break-lg) {
        flex-direction: row;
      }

      & > :global(div.athenaeum-form-inputGroup) {
        flex: 1;
      }
      & > :global(div.athenaeum-form-inputGroup) {
        @extend .inputWithPrependInsideInput;
      }

      & :global(.athenaeum-form-prepend) {
        background: unset;
        border: unset;
      }

      & :global(.athenaeum-date-input-dateInput input) {
        background: unset !important;
        border: unset !important;
      }

      .inputSeparator {
        background-color: rgba(0, 0, 0, 0.3);
        margin: 0 1rem;
        height: 1px;
        @media (min-width: $break-lg) {
          margin: 1rem 0;
          height: unset;
          width: 1px;
        }
      }

      .inputsContainerActionButton {
        padding: 1rem 3rem;
        justify-content: center;
      }

      .locationDropdown {
        font-size: 1rem;
        span, i {
          font-size: 1rem;
        }
        & :global(.athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer) {
          background-color: var(--light) !important;
        }
        >div{

          >div {
            >div {
              >div {
                min-height: 60px !important;
                border:0!important;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
              }
            }
          }
        }
        @media (min-width: $break-md) {
          grid-column: span 2;
        }
        @media (min-width: $break-lg) {
          grid-column: span 3;
        }
      }

      input {
        height: unset;
        padding: 1rem;
        box-shadow: none !important;
      }

      .dateInput {
        font-size: 1rem;
        span, i {
          font-size: 1rem;
        }
        & > div {
          flex: 1;
          align-items: center;
        }
      }
    }
  }
}
