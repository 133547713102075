@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }

    .doctorContainer {
      justify-content: space-between;
      
      .doctorText {
        margin-right: 20px;
        @media screen and (max-width: $break-md) {
          min-width: auto;
        }
        min-width: auto;
      }
      
      .redirectionButtonDiv{
        display: flex;
        flex-direction: row;
        justify-content: left;
        justify-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media screen and (max-width: $break-md) {
          justify-content: center;
          justify-items: center;
        }
      }

      display: flex;
      @media screen and (max-width: $break-md) {
        flex-direction: column-reverse;
        img {
          border-radius: 100%;
          width: 250px;
          height: 250px;
          object-fit: cover;
          object-position: center top;
          margin: auto;
        }
      }
    }
  }
}
