@import "../../theme/variables";
@import "../../theme/mixins";

.hitWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  margin-top: 1rem;
  padding: 0.5rem;
  
  .hitTextContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
    .hitHeader {
      font-weight: bold;
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
    
    .hitTitle {
      margin-top: -0.7rem;
      font-size: 1.1rem;
    }
    
  }
  
  .hitButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    cursor: pointer;

    @media (min-width: $break-md) {
      padding-right: 1rem;
    }
    
    .hitButton {
      background-color: white;
      border: none;
    }
  }
}

