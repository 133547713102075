@import "variables";

/* Fonts */
@font-face {
  font-family: "RalewayRegular";
  src: url("../fonts/raleway/webfonts/Raleway-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "RalewaySemiBold";
  src: url("../fonts/raleway/webfonts/Raleway-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "HeeboMedium";
  src: url("../fonts/heebo/webfonts/Heebo-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AsapBold";
  src: url("../fonts/asap/Asap-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AsapMedium";
  src: url("../fonts/asap/Asap-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AsapSemiBold";
  src: url("../fonts/asap/Asap-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AsaptLight";
  src: url("../fonts/asap/Asap-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AsapRegular";
  src: url("../fonts/asap/Asap-Regular.ttf");
  font-display: swap;
}

/* CSS variables values should match scss variables. */
:root {
  --fenix-font-family-regular: "AsapRegular";
  --fenix-font-family-medium: "AsapMedium";
  --fenix-font-family-semibold: "AsapSemiBold";
  --border-radious-xl: 1.25rem;
  --border-radious-lg: 1rem;
  --border-radious-md: 0.75rem;
  --border-radious-sm: 0.5rem;
  --light: #{$light};
  --light-200: #{$light-hover-color};
  --carbon: #606060;
  --carbon-200: #a0a0a0;
  --primary: #{$primary};
  --primary-hover: #{$primary-hover};
  --fenix-card-background: #f283253b;
  --fenix-appointment-booking-background: #fbf2e9;
  --fenix-app-header-background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("../images/background/Fenix-header-cropped.jpg") no-repeat center;
  --fenix-app-home-header-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
  url("../images/background/Header-desktop.jpg") no-repeat center;
  --fenix-app-home-header-background-mobile: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
  url("../images/background/Header-mobile.jpg") no-repeat center;
  --fenix-app-recent-header-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
  url("../images/background/Ajankohtaista_osio_large.jpg") no-repeat center;
  --fenix-app-digiClinic-header-background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
  url("../images/background/DigiClinic-header-crop.jpg") no-repeat center;
  --fenix-app-header-background-specialized-clinics: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("../images/background/Fenix-header-cropped.jpg") no-repeat center;
  --fenix-navbar-height: 80px;
  --fenix-page-padding: calc(var(--fenix-navbar-height) + 6rem) 1rem;
  //  --fenix-app-header-background-appointment-booking: [Optional overwrite];
}

/* Global style rules */

html {
  background: no-repeat fixed center;
  background-size: cover;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fenix-font-family-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .modal-backdrop.show ~ .modal-backdrop.show {
    display: none;
  }

  .modal.show.hidden {
    display: none !important;
  }
  h2 {
    font-size: 36px;
    opacity: 1;
    margin-bottom: 40px;
    color: #606060;
    font-family: "AsapMedium";
  }


  /*p > strong{
    font-size: 19px;
  }*/

  h3 {
    margin-bottom: 20px;
    font-size: 24px;
    margin-top: 40px;
    color: $primary;
  }
  p {
    color: #606060;
    opacity: 1;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.athenaeum-layout-layout {
  flex: 1;
}
button {
  font-size: 16px;
}

/* Text Formatting */

mark {
  background-color: initial;
  padding: initial;
  color: $blue;
}

/* Padding exceptions */

.p-1-except-right {
  padding: 0.25rem 0 0.25rem 0.25rem !important;
}

.p-1-except-left {
  padding: 0.25rem 0.25rem 0.25rem 0 !important;
}

.p-1-except-left.p-1-except-right {
  padding: 0.25rem 0 0.25rem 0 !important;
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

a {
  color: $primary;
}

.flex-2 {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0;
}
//  Some Bootstrap 5 UtilityClasses
//  Remove on update.
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1rem;
}
.gap-4 {
  gap: 1.5rem;
}
.gap-5 {
  gap: 3rem;
}

.athenaeum-layout-layout .athenaeum-layout-main.athenaeum-layout-topNav {
  margin-top: unset !important;
}

.modal-open.mobile .modal-backdrop.show {
  opacity: 0.5 !important;
}
