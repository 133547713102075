@import "../../theme/variables";
@import "../../theme/mixins";

.accordionHeaderContainer {
  background-color: #F28325 !important;
}

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();

      .pricingDiv:first-child{
        padding-bottom: 4rem;
        border-bottom: 1px solid rgba(96, 96, 96, 0.5);
      }
    }
  }

  li{
    color: var(--carbon);
  }

  li::marker {
    color: var(--carbon);
  }
  
  .pricingContentWithCardContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    
    .pricingContentTextDiv{
      grid-column: span 6;

      @media (min-width: $break-xxs) {
        grid-column: span 12;
      }
      @media (min-width: $break-lg) {
        grid-column: span 6;
      }
    }
    
    .pricingInfoCard{
      display: flex;
      flex-direction: column;
      grid-column: span 6;
      background-color: rgb(251, 242, 233);
      border-radius: var(--border-radious-md);
      padding: 2rem;

      @media (min-width: $break-xxs) {
        grid-column: span 12;
      }
      @media (min-width: $break-lg) {
        grid-column: span 6;
      }
      
      .pricingInfoCardHeader{
        display: flex;
        margin-bottom: 0.5rem;
        align-items: center;
        span{
          color: var(--carbon);
          font-weight: bold;
          font-size: 20px;
        }
        img{
          max-height: 30px;
          margin-right: 0.25rem;
        }
      }
      
      .pricingInfoCardContent{
        margin-bottom: 0.5rem;
      }
      
    }
  }

  .priceAccordion {
    margin-top: 20px;
    box-shadow: none;
    border-radius: 13px;

    .accordionHeaderContainer {
      background-color: #F28325;
    }
    
    hr {
      display: none;
    }
  }

  .pricesTable {
    table{
      width: 100%;
    }
    thead tr {
      border-bottom:0;
    }
    tr {
      border-bottom: 1px solid rgba(96, 96, 96, 0.3);
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      color: var(--carbon);
      word-break: break-word;

      @media (min-width: $break-xxs) {
        flex-direction: column;
      }
      @media (min-width: $break-md) {
        flex-direction: row;
      }
      
      th:last-child{
        margin-right: 40px;
      }
      
      td:first-child{
        @media (min-width: $break-xxs) {
          font-weight: bold;
          margin-top: 0.5rem;
          margin-bottom: 0;
        }
        @media (min-width: $break-md) {
          font-weight: normal;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      
      td{
        font-size: 16px;
        @media (min-width: $break-xxs) {
          margin-bottom: 0.5rem;
        }
        @media (min-width: $break-md) {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      td:last-child{
        margin-right: 40px;
      }
    }

  }
}
