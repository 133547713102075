@import "../../theme/variables";

.notifications {
  &.mobile {
    margin: 0 !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $baseline $baseline/2;

    .showMore {
      margin-left: $baseline;
      margin-right: $baseline;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .noNotifications {
    padding-left: $baseline * 1.5;
  }
}
