/* Sizes */
$break-xxs: 310px;
$break-360px: 360px;
$break-400px: 400px;
$break-xs: 480px;
$break-sm: 640px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1280px;
$break-2xl: 1536px;

$baseline: 8px;
$radius: 6px;

/* Colors */
$primary: #F28325;
$primary-hover: #a95c1a;
$primary-disable-color: #f6a866;

$secondary-color: #a7ceca;
$secondary-hover-color: #72d0c8;
$secondary-disable-color: #b0b6ba;

$danger-color: #c83609;
$danger-hover-color: #932c0c;
$danger-disable-color: #e27a5a;

$success-color: #28a745;
$success-hover-color: #218838;
$success-disable-color: #74c686;

$warning-color: #ffc107;
$warning-hover-color: #e0a800;
$warning-disable-color: #ffd75e;

$info-color: #31a0ff;
$info-hover-color: #65b4f7;
$info-disable-color: #a4d2f8;
$light: #f8f9fa;
$light-hover-color: #e2e6ea;
$light-disable-color: #efefef;

$dark-color: #343a40;
$dark-hover-color: #23272b;
$dark-disable-color: #7a7e83;

$transparent-dark-orange: rgba(234, 60, 0, 0.9);
$light-gray: rgb(227, 227, 227);
$grey: #adadad;
$dark-gray: rgb(65, 64, 66);
$white: rgb(255, 255, 255);
$transparent-white: rgba(255, 255, 255, 0.95);
$text-black: #333;
$danger: #a94442;
$blue: #337ab7;
$dark-blue: #0000aa;
$green: #33b77a;
$separator-color: rgba(0, 0, 0, 0.1);
$approved: #28a74520;
$declined: #a9444220;
$processed: #F1F3FFFF;
$completed: #17A2B811;
$new: #ffb34760;

/* Shadows */
$primary-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
$box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

/* Border */
$primary-border: 1px solid rgba(0, 0, 0, 0.15);
