@import "../../theme/variables";
@import "../../theme/mixins";

.container {
  background-color: #fbf2e8;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 4rem 1rem;
}

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-service-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
  }
  
  .blogViewEmpty {
    text-align: center;
    grid-column: 1 / span 12;
  }

  .blogPost {
    background: white;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    color: var(--carbon);
    border-radius: var(--border-radious-md);

    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: span 6;
    }
    @media (min-width: $break-xl) {
      grid-column: span 6;
    }
    @media (min-width: $break-2xl) {
      grid-column: span 6;
    }

    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
    button,
    a {
      color: var(--primary) !important;
      font-weight: bold;

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--primary-hover) !important;
        background-color: transparent !important;
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 80%;
      object-fit: cover;
      object-position: center;
    }
    h4 {
      font-family: var(--fenix-font-family-semibold);
    }
    
    .blogPostImageDiv {
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    .blogPostImage {
      object-fit: contain;
    }
    
    .blogPostLogo {
      object-fit: contain;
    }

    .blogPostContent {
      flex: 4;
      padding: 1rem 0;
    }
  }
  
  .blogContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 0;
  }
  
  .noRecentPostsDiv {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    text-align: center;
    grid-column: 1 / span 12;
    color: var(--primary);
    
    img {
      margin: auto;
      max-width: 200px;
      padding: 1rem;
    }
  }
  
  .blogPostViewContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    
    .blogPostHeader {
      text-align: center;
      padding: 4rem 1rem;
      display: flex;
      flex-direction: column;
      font-family: var(--fenix-font-family-semibold);
      opacity: 0.85;
      
      h3 {
        color: black
      }
      
      .blogPostDate {
        text-align: center;
        font-family: var(--fenix-font-family-regular);
        opacity: 1.0;
      }
    }

    .header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--carbon);
      padding: 0.5rem 0;
      font-family: var(--fenix-font-family-semibold);
      h4 {
        margin: 0;
      }
      button,
      a {
        color: var(--carbon) !important;
        font-weight: bold;

        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          color: var(--carbon-200) !important;
          background-color: transparent !important;
        }
      }

      & > div:last-child {
        position: absolute;
        border-bottom: 1px solid black;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    
    .blogPostImage {
      align-items: center;
      max-width: 100%;
      
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    
    .blogPostContentPart {
      align-items: center;
      text-align: center;
      padding: 4rem 1rem;
      
      ul {
        display: inline-block;
        list-style-position: inside;
        text-align: left;
        color: var(--carbon);
        

        li:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
      
      ol {
        color: var(--carbon);
        gap: 0.5rem;
        display: inline-block;
        list-style-position: inside;
        text-align: left;

        li:not(:first-child) {
          margin-top: 0.5rem;
        }
        
      }
    }

    .blogPostAuthorDiv {
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 5rem;
      font-style: italic;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }

      .blogPosts {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        padding: 1rem 0;
      }
      
    }
  }
  
  .blogLoadingSpinner {
    grid-column: 1 / span 12;
    display: flex;
    justify-content: center;
  }
  
  .loadMoreContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .blogPostListItem {
    background: #fbf2e8;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    color: var(--carbon);
    border-radius: var(--border-radious-md);
    height: 100%;
    min-height: 15vh;

    grid-column: 1 / span 12;

    @media screen and (max-width: $break-md) {
      flex-direction: column-reverse;
      height: 100%;
      img {
        object-fit: contain;
        object-position: center top;
        margin: auto;
      }
    }

    @media (min-width: $break-lg) {
      grid-column: span 12;
    }
    @media (min-width: $break-xl) {
      grid-column: span 12;
    }
    @media (min-width: $break-2xl) {
      grid-column: span 12;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: bold;
    }

    h5 {
      font-size: 1rem;
    }

    

    .blogPostImageDiv {
      display: flex;
      flex: 4 2;
      align-items: center;
      justify-content: center;
      
      .blogPostLogo {
        object-fit: contain;
      }
      
      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      @media screen and (max-width: $break-md) {
        max-height: 100%;
        
        img  {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }

    .blogPostContentDiv {
      flex: 5 1;
      padding: 1rem 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .blogHeaderDiv {
        display: flex;
        padding-bottom: 1rem;
      }
      
      .blogSubtitleDiv {
        padding-top: 0.5rem;
      }
      
      .blogDateAuthorDiv {
        padding-top: 1rem;
        display: flex;
        flex-direction: row;
        gap: 4rem;
        
        span {
          font-size: 0.9rem;
          font-style: italic;
        }
      }
    }
    
    .blogPostButtonDiv {
      padding-top: 1rem;
      display: flex;
    }
  }

  .item {
    align-items: center;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }

  .disabledPage {
    color: #808e9b;
  }

  .active {
    border-bottom: 3px solid var(--primary);
    color: black !important;
  }

  .break-me {
  }

  .next {
    flex: 2;
    width: 150px;
    color: var(--primary) !important;
    font-weight: bold;
    text-align: center;
  }

  .paginationContainer {
    ul {
      margin: 0;
      padding: 0;
    }

    border-radius: var(--border-radious-md);
    
    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: 1 / span 12;
    }
    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }
  }

  .pagination {
    align-items: center;
    background-color: #fbf2e8;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    width: 100%;
    border-radius: var(--border-radious-md);
  }

  .paginationPage {
    font-weight: 700;
    margin-left: 1rem;
    margin-right: 1rem;
    color: #808e9b;
  }

  .previous {
    text-align: center;
    flex: 2;
    width: 150px;
    color: var(--primary) !important;
    font-weight: bold;
  }
  
}