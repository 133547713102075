@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .serviceAccordion {
    border: 0.25px solid #606060;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .selectList {
    height: 500px;
    color: #f283250d;
  }

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .selected {
    background-color: #f283250d !important;
    color: #606060 !important;
  }

  .accordionContainer{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .companyAccordion {
    margin-top: 20px;
    box-shadow: none;
    border-radius: 13px;

    .accordionHeaderContainer {
      background-color: #F28325;
    }

    hr {
      display: none;
    }
  }

  .innerListItem {
    margin-left: 20px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border: unset;
    background-color: unset;
    text-align: left;
    width: 100%;
    border-radius: var(--border-radious-sm);
  }

  .listItem:hover {
    background-color: #f283250d; /* Change the color and opacity as desired */
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
    
    h5 {
      color: var(--primary);
    }
    
    li{
      color: var(--carbon);
    }

    li::marker {
      color: var(--carbon);
    }
  }
}
