@import "../../theme/variables";

.admin {
  .menu {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: #929292;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .menuLink:not(:last-of-type) {
    border-bottom: 1px #bcbcbc solid;
  }

  .menuLink {
    padding: 10px 15px 8px 15px;
    display: block;
    font-size: 1.1em;
    overflow: hidden;
    color: white;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .menuLink:first-child {
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .menuLink:last-of-type {
    border-radius: 0 0 5px 5px;
  }

  .menuLink:active,
  .menuLink:focus,
  .menuLink.active {
    background: lightgray;
    color: black;
  }

  .menuLink:hover {
    background-color: #b2b2b2;
  }

  Link.menuLink:focus,
  Link:hover {
    color: black;
    text-decoration: none;
  }
}
