@import "../../../theme/variables";

.notificationItem {
  border: $primary-border;
  box-shadow: $box-shadow;

  &:not(:last-child) {
    margin-bottom: $baseline;
  }

  .context {
    padding: $baseline;
    cursor: default;

    &.active {
      background-color: #ffffe1;
    }

    &.pageRoute {
      cursor: pointer;
    }

    > span {
      display: inline-block;
      color: black;
      width: 100%;

      &.createdAt {
        color: $primary-disable-color;
        font-size: 0.9em;
      }

      &.message {
        font-size: 0.8em;
      }
    }
  }
}
