@import "../../theme/variables";
@import "../../theme/mixins";

.homePageCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
  height: min-content;
  border-radius: var(--border-radious-xl);
  grid-column: 1 / span 12;

  @media (min-width: $break-lg) {
    grid-column: 1 / span 4;
  }
  @media (min-width: $break-xl) {
    grid-column: 2 / span 4;
  }
  @media (min-width: $break-2xl) {
    grid-column: 3 / span 3;
  }

  button {
    border-radius: var(--border-radious-sm);
    font-size: 1rem !important;
    font-weight: normal;
    padding: 0.8rem 2.5rem;
    width: 100%;
  }

  .homePageCardHeader {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--carbon);
    font-family: var(--fenix-font-family-medium);
    margin-bottom: 1rem;
  }

  .homePageCardContent {
    font-size: 1.1rem;
    color: white;
  }
  
  .imageDiv {
    width: 100%;
    min-height: 500px;
    border-radius: 11px;
  }
  
  .textContainer {
    padding: 1rem;
  }
  
}

.homePageCardNoBGImg {
  @extend .homePageCard;
  background: linear-gradient(rgba(#FBF2E9, 0.7), rgba(#FBF2E9, 0.7)), url("../../images/background/Landscape-786x590.jpg") no-repeat right;
  background-size: cover;
}

.homePageCardButtonDiv {
  display: flex;
  justify-content: center;
  justify-items: center;
}