@import "../../theme/variables";



.doctorsContainer {
  display: inline-flex;
  flex-wrap: wrap;

  .card {
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;

    img {
      width: 100%;
      height: 20vh;
      border-radius: 5px 5px 0 0;
    }
  }

  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
}



