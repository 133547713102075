@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
  }

  .serviceAccordion {
    border: 0.25px solid #606060;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .selectList {
    height: 500px;
    color: #f283250d;
  }

  .selected {
    background-color: #f283250d !important;
    color: #606060 !important;
  }

  .innerListItem {
    margin-left: 20px;
  }

  .doctorsContainer {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $break-md) {
      justify-content: center;
    }
  }

  .doctor {
    .titleContainer {
      h3 {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
      }

      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 20px;
      flex-direction: column;
      min-height: 100px;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: calc(100% / 3 - 40px);
    margin: 20px;

    .doctorImage {
      cursor: pointer;
      border-radius: 100%;
      width: 198px;
      height: 198px;
      object-fit: cover;
      object-position: center top;
    }
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border: unset;
    background-color: unset;
    text-align: left;
    width: 100%;
    border-radius: var(--border-radious-sm);
  }

  .listItem:hover {
    background-color: #f283250d; /* Change the color and opacity as desired */
  }
}
