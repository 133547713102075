@import "../../theme/variables";

.bottomButtonDiv{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  :global(button.btn-primary) {
    span{
      font-weight: bold;
    }
  }

  :global(button:not(.btn-primary)) {
    background-color: transparent !important;
    border-color: var(--primary) !important;
    color: var(--primary) !important;
    border-width: 2px;
    grid-column: span 12;

    span{
      color: var(--carbon) !important;
      font-weight: bold;
    }

    &:hover,
    &:focus,
    &:focus-within {
      background-color: var(--primary) !important;
      color: white !important;

      span{
        color: white !important;
      }
    }

  }
}

.appointmentInfoDiv{
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  gap: 0.2rem;
  margin-bottom: 1rem;
  
  p{
    margin: 0 !important;
  }

  @media (max-width: $break-md) {
    grid-template-columns: 1fr 1fr;
  }
}
.headerSpan{
  margin-bottom: 0.5rem !important;
  font-weight: bold;
  color: var(--carbon);
}
