@import "../../theme/variables";

.container {
  background-color: #fbf2e8;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 4rem 1rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: 1 / span 12;
    }
    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }

    .blogPosts {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;
      padding: 1rem 0;
    }

    .facebookPosts {
      display: flex;
      padding: 1rem 0;
    }
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--carbon);
    padding: 0.5rem 0;
    font-family: var(--fenix-font-family-semibold);
    h4 {
      margin: 0;
    }
    button,
    a {
      color: var(--carbon) !important;
      font-weight: bold;

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--carbon-200) !important;
        background-color: transparent !important;
      }
    }

    & > div:last-child {
      position: absolute;
      border-bottom: 1px solid black;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .blogPost {
    background: white;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    color: var(--carbon);
    border-radius: var(--border-radious-md);

    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: span 6;
    }

    @media (max-width: $break-md) {
      flex-direction: column;
    }
    

    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
    button,
    a {
      color: var(--primary) !important;
      font-weight: bold;

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--primary-hover) !important;
        background-color: transparent !important;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    h4 {
      font-family: var(--fenix-font-family-semibold);
    }

    .blogPostImageDiv {
      flex: 3;
      max-height: 10rem;
    }
    
    .blogPostImage {
      object-fit: cover;
    }
    
    .blogPostLogo {
      object-fit: contain;
    }

    .blogPostContent {
      flex: 4;
      padding: 1rem 0;
    }
  }

  .facebookPost {
    background: white;
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    color: var(--carbon);
    border-radius: var(--border-radious-md);

    grid-column: 1 / span 12;

    @media (min-width: $break-lg) {
      grid-column: span 4;
    }
    @media (min-width: $break-xl) {
      grid-column: span 4;
    }
    @media (min-width: $break-2xl) {
      grid-column: span 4;
    }

    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
    button,
    a {
      color: var(--primary) !important;
      font-weight: bold;

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--primary-hover) !important;
        background-color: transparent !important;
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 350px;
      object-fit: contain;
      object-position: top;
    }
    h4 {
      font-family: var(--fenix-font-family-semibold);
    }

    .facebookPostImage {
      flex: 0;
    }

    .facebookPostContent {
      flex: 4;
      padding: 1rem 0;
      word-wrap: break-word;
      white-space: pre-wrap;
      object-position: top;
      
      small {
        color: orange;
        cursor: pointer;
      }
    }
    
    .facebookPostLinks {
      object-position: bottom
    }
  }
}
