@import "../../theme/variables";
@import "../../theme/mixins";

.searchContainerDiv {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  z-index: 9999;
  width: 10rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 10px;

  
}


.searchBarDiv {
  display: grid;
  grid-column: 1 / span 12;
  width: 10rem;
  justify-content: end;
  align-items: center;
  border-radius: 10px;
  //box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);

  input[type="text"]:focus {
    width: 20rem;
    outline: none;
    border-style: none;
    //transition: width 0.4s ease;
  }

  input[type="text"] {
    grid-column: 1 / span 9;
    border-style: none;
    padding: 0.6rem;
    font-size: 1rem;
  }
  
  .inputContainer {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 100000;
    padding: 0.3rem;

    box-shadow: 0 0 0 100vmax rgba(0,0,0, 0);
    //transition: 0.5s box-shadow;


    &:focus-within{
      background-color: white;
      box-shadow: 0 0 0 100vmax rgba(0,0,0, .4);
      
      /*.searchResultsContainer{
        display: grid;
      }*/
      
      .loadingContainer {
        display: flex;
      }
      
      .inputDiv {
        box-shadow: none;
      }
    }
    
    .loadingContainer {
      display: none;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 2rem;
      grid-column: 1 / span 12;
      flex-direction: column;
      width: 25.6rem;
      position: absolute;
      padding: 1rem 0.3rem 1rem 0.3rem;
      top: 2.4rem;
      right: 0;
      background-color: white;
      z-index: 1000;
      border-radius: 0px 0px 10px 10px;
    }

    .searchResultsContainer{
      display: grid;
      grid-column: 1 / span 12;
      flex-direction: column;
      width: 25.6rem;
      position: absolute;
      padding: 1rem 0.3rem 1rem 0.3rem;
      top: 2.4rem;
      right: 0;
      background-color: white;
      z-index: 10000;
      border-radius: 0px 0px 10px 10px;
      
    }
    
    .inputDiv {
      display: flex;
      flex-direction: row;
      z-index: 10000;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    
    .resultsDiv {
      display: flex;
      flex-direction: row;
      top: 0;
    }
  }
  
  
}

.searchResultsDiv{
  background-color: white;
  border-top: 1px solid rgba(96,96,96,0.5);

  .noResultsDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
  }

  .searchResultsRow{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 0.5rem;
    border-bottom: 1px solid rgba(96,96,96,0.5);
    justify-content: space-between;
    cursor: pointer;
    z-index: 1000000;

    .searchResultHitText{
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column: 1 / span 11;
      flex-direction: row;
      z-index: 1000000000000;
      
      .categorySpan {
        font-weight: bold;
        font-size: 1.1rem;
        grid-column: 1 / span 5;
      }
      
      .categoryNumberOfHitsSpan {
        grid-column: 6 / span 7;
        font-size: 1.1rem;
      }
    }

    .mobileSearchResultHitText {
      display: flex;
      grid-column: 1 / span 10;
      flex-direction: column;
      padding-left: 1rem;

      .mobileCategorySpan{
        font-weight: bold;
        margin-right: 2rem;
      }
    }
  }
  

  .hitButtonContainer {
    display: flex;
    grid-column: 12 / span 1;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    padding-right: 1rem;
    cursor: pointer;

    .hitButton {
      background-color: white;
      border: none;
    }
  }
}


.searchInput {
  width: 5rem;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}

.searchButton {
  width: 5rem;
  border-radius: 0px 10px 10px 0px;
  background-color: var(--primary);
  border: none;
  color: white;
  
  i {
    margin-right: 0.5rem;
    
  }
}

.mobileSearchButtonDiv {
  margin-right: 0.5rem;
  
  .mobileSearchButton{
    display: flex;
    justify-content: center;
    text-align: center;
    border: none;
    background-color: white;
    font-size: 1.8rem;
  }
}

.mobileSearchContainer{
  position: absolute;
  top: 0.5rem;
  z-index: 10000;
  box-shadow: 0 0 0 100vmax rgba(0,0,0, .4);
  transition: all 0.2s ease-in;
  left: 0.5rem !important;
  right: 0.5rem !important;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  overflow: hidden;
  
  .mobileSearchCloseRow {
    display: flex;
    flex-direction: row-reverse;
    
    .mobileSearchCloseButton{
      padding-top: 1rem;
      padding-right: 1rem;
      background-color: white;
      border: none;
      font-size: 1.2rem;
    }
    
  }
  
  .mobileSearchHeaderDiv {
    display: flex;
    flex-direction: row;
    padding-left: 1.5rem;
  }
  
  .mobileSearchInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    margin: 0 1.5rem 0 1.5rem;
    height: 3rem;
    margin-bottom: 2rem;
    
    .mobileSearchInputDiv{
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    input[type="text"] {
      grid-column: 1 / span 9;
      border-style: none;
      border-radius: 15px 0px 0px  15px;
      padding: 0 0.6rem 0 0.6rem;
    }
    
    button {
      grid-column: 10 / span 3;
      height: 100%;
      border: none;
      background-color: var(--primary);
      border-radius: 0 15px 15px 0;
      color: white;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
  
  .mobileSearchResultsContainer {
    
    .mobileLoadingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 2rem;
      grid-column: 1 / span 12;
      flex-direction: column;
    }
  }
  
}