@import "../../theme/variables";

.userManagement {
  .tabContainer {
    min-height: 40vh;
  }

  .invitations {
    margin-bottom: $baseline * 2;
  }
}
