@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-service-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
  }

  li{
    color: var(--carbon);
  }

  li::marker {
    color: var(--carbon);
  }

  .appointmentButton {
    cursor: pointer;
    overflow: hidden;
    grid-column: span 12;

    @media (min-width: $break-md) {
      grid-column: span 6;
    }
    @media (min-width: $break-lg) {
      grid-column: span 6;
    }
    @media (min-width: $break-xl) {
      grid-column: span 3;
    }
    @media (min-width: $break-2xl) {
      grid-column: span 3;
    }
  }

  .reserveTimeCard {
    background-color: var(--fenix-appointment-booking-background);
    border-radius: 22px;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    .search {
      button {
        border-radius: var(--border-radious-sm);
        padding: 0 3rem;
      }
    }

    .cardHeader {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1.4rem;
      color: var(--carbon);
    }

    .reserveTimeNav {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(12, 1fr);

      button {
        border: 2px solid var(--primary) !important;
        border-radius: var(--border-radious-xl) !important;
      }

      button:not(first-child) {
        border: 2px solid var(--primary) !important;
        background-color: transparent;
        color: var(--primary);

        &:hover {
          background-color: var(--primary) !important;
          color: white !important;
        }
      }
    }
  }

  .voucherAccordionContainer{
    margin-top: 2rem;
    margin-bottom: 2rem;
    h4{
      color: var(--carbon);
    }
  }

  .clinicAccordion {
    margin-top: 20px;
    box-shadow: none;
    border-radius: 13px;

    .accordionHeaderContainer {
      background-color: #F28325;
    }

    hr {
      display: none;
    }
  }
  
  .accordionContainer{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .readMoreDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    i{
      color: var(--primary);
    }
    
    span{
      margin-left: 0.5rem;
      font-weight: bold;
      color: var(--carbon);
    }
  }
  
  .serviceVouchersContainer{
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    .availableVouchersHeader{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;

      span{
        margin-left: 0.5rem;
        font-weight: bold;
        color: var(--carbon);
      }
    }
    
    .availableVouchersButtonContainer{
      
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;

      :global(button:not(.btn-primary)) {
        background-color: transparent !important;
        border-color: var(--primary) !important;
        color: var(--primary) !important;
        border-width: 2px;
        grid-column: span 12;
        
        span{
          color: var(--carbon) !important;
          font-weight: bold;
        }

        @media (min-width: $break-sm) {
          grid-column: span 6;
        }
        @media (min-width: $break-md) {
          grid-column: span 6;
        }
        @media (min-width: $break-lg) {
          grid-column: span 4;
        }
        @media (min-width: $break-xl) {
          grid-column: span 4;
        }
        @media (min-width: $break-2xl) {
          grid-column: span 3;
        }
        
      }
      
    }
  }
  
  .teamIncludesHeader{
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    justify-content: center;
    
    @media (min-width: $break-md) {
      justify-content: start;
    }
    
    span{
      font-weight: bold;
      font-size: large;
      color: var(--carbon);
    }
  }

  .doctorsContainer {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $break-md) {
      flex-direction: column;
      margin-left: 0;
    }

  }

  .doctor {
    .titleContainer {
      h3{
        margin-top:0;
        margin-bottom: 0;
        text-align: center;
      }

      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 20px;
      flex-direction: column;
      min-height: 100px;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: calc(100% / 3 - 40px);
    margin: 20px;

    .doctorImage {
      cursor: pointer;
      border-radius: 100%;
      width: 175px;
      height: 175px;
      object-fit: cover;
      object-position: center top;
    }
  }

}
