@import "../../theme/variables";
@import "../../theme/mixins";

.digiClinicStep {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  
  @media(min-width: $break-md){
    gap: 3rem;
  }
}

.stepNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  .stepNumberDiv {
    border: 3px solid var(--primary);
    border-radius: 15px;
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
  }
  
}

.stepTextContainer {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  
  .stepHeader {
    font-weight: bold;
  }
}