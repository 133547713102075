@import "./variables";

.pageContainer {
  padding: 0 16px !important;

  > div {
    border-radius: 0 !important;
    height: 100%;
    padding: 0 $baseline/2 !important;
  }

  .alert {
    max-width: 628px;
    margin-top: 0;
    margin-bottom: 4px;
    text-align: center;
    background: $transparent-dark-orange;
    border: none;
    cursor: default;
    color: white;
    border-radius: 5px;

    @media screen and (max-width: $break-md) {
      margin: 0 6px 8px 6px;
    }
  }

  .pageRow {
    margin: 0 -4px 4px !important;
    padding: $baseline 15px;
    padding-bottom: 0 !important;
    display: flex;
    align-content: center;

    > button {
      margin-left: auto;
      margin-right: auto;
      height: 80px;
    }
  }
}

.navigation {
  @media only screen and (max-width: $break-md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.bigButton {
  height: 60px;
  white-space: normal;
  outline: none !important;
  box-shadow: none !important;
  text-transform: uppercase;

  :global {
    .actions-container {
      top: 58px;
    }

    .actions-icon {
      top: 16px !important;
      font-size: 24px !important;
    }
  }
}

/* Renta components overrides */

:global {
  /* Navigation */

  .athenaeum-top-nav-navigation {
    background-color: transparent !important;
    box-shadow: none !important;
    justify-content: flex-end !important;
    font-family: var(--fenix-font-family-regular);
    font-size: 24px;
    color: #000000 !important;

    .athenaeum-top-nav-left img {
      height: 64px !important;
      filter: brightness(100);
    }
    .athenaeum-top-nav-right_infoIcon,
    .athenaeum-top-nav-right_hamburgerIcon,
    .athenaeum-top-nav-left_backIcon {
      color: var(--light);

      &:hover {
        color: $primary-hover;
        background-color: var(--primary) !important;
      }
    }
  }
  .athenaeum-top-nav-navigation {
    position: absolute !important;
    padding: 1rem;
    height: var(--fenix-navbar-height) !important;
    transition: background-color 0.1s ease-in;
    background-color: transparent !important;
    .athenaeum-top-nav-right,
    .athenaeum-language-dropdown-dropdown {
      //   Let the language dropdown and hamburger menu be calculated from top of the page
      position: unset !important;
    }
    div.athenaeum-top-nav-left_backIcon {
      transition: color 0.1s ease-in;
      color: var(--light);
      &:hover {
        background-color: unset !important;
        color: var(--light-200);
      }
      i {
        &::before {
          font-weight: 500 !important;
        }
      }
    }

    .athenaeum-top-nav-link {
      color: white !important;
      font-family: var(--fenix-font-family-regular);
      font-size: 1.2rem;
      transition: color 0.1s ease-in;
      &:hover {
        outline: none;
        color: var(--light-200) !important;
        background-color: transparent !important;
      }
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--primary) !important;
        background-color: transparent !important;
      }
      
    }

    div.athenaeum-top-nav-right_hamburgerIcon {
      margin: 0;
      padding: 0.6rem;
      border: 2px solid white;
      border-radius: var(--border-radious-sm);
      justify-content: center;
      aspect-ratio: 1;
      align-items: center;
      width: unset;
      i {
        color: white !important;
        font-weight: 500 !important;
        font-size: 1.4rem !important;
      }
      &:hover {
        background-color: unset !important;
      }
    }

    ul.athenaeum-hamburger-hamburger,
    ul.athenaeum-hamburger-hamburger_open {
      transition: all 0.2s ease-in;
      padding: 0;
      background-color: white;
      left: 0.5rem !important;
      right: 0.5rem !important;
      width: unset !important;
      top: calc(var(--fenix-navbar-height) - 0.5rem);
      border-radius: var(--border-radious-sm);
      @media (min-width: $break-md) {
        left: unset !important;
        right: 1rem !important;
        width: 200px !important;
      }
      li {
        border: none !important;
        &:first-child {
          padding-top: 1rem;
        }
        &:last-child {
          padding-bottom: 1rem;
        }
        a {
          color: black;
          padding: 0.5rem 2rem;
          font-family: var(--fenix-font-family-regular);
          font-weight: 500;
          font-size: 1.2rem;

          &:hover {
            background-color: var(--light-200) !important;
          }
        }
        
        &:last-child {
          a{
            color: var(--primary) !important;
          }
          
        }
      }
    }

    .athenaeum-language-dropdown-dropdown {
      border: none !important;
      border-radius: var(--border-radious-sm);
      span {
        color: white !important;
        font-size: 1.2rem;

        &:hover {
          background-color: unset !important;
        }
      }

      .athenaeum-language-dropdown-dropdown_open {
        transition: top 0.2s ease-in;
        color: black !important;
        background-color: white !important;
        top: calc(var(--fenix-navbar-height) - 0.5rem);
        left: 0.5rem !important;
        right: 0.5rem !important;
        width: unset !important;
        @media (min-width: $break-md) {
          left: unset !important;
          right: 1rem !important;
          width: 200px !important;
        }
        li {
          transition: background-color 0.2s ease-in;
          padding: unset !important;
          &:first-child {
            padding-top: 1rem;
          }
          &:last-child {
            padding-bottom: 1rem;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.2) !important;
          }

          span {
            color: black !important;
            font-family: var(--fenix-font-family-regular);
            font-size: 1.2rem;
            font-weight: 500 !important;
            padding: 0.5rem 2rem;
          }
        }
      }
    }

    &:not([blended="true"]) {
      background-color: white !important;

      .athenaeum-top-nav-left img {
        filter: unset;
      }
      div.athenaeum-top-nav-left_backIcon {
        color: var(--primary);
        &:hover {
          color: var(--primary-hover);
        }
      }

      .athenaeum-top-nav-link {
        color: black !important;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          color: var(--primary) !important;
          background-color: transparent !important;
        }
      }

      div.athenaeum-top-nav-right_hamburgerIcon {
        border-color: black;
        i {
          color: black !important;
        }
        &:hover {
          color: black !important;
        }
      }

      ul.athenaeum-hamburger-hamburger,
      ul.athenaeum-hamburger-hamburger_open {
        top: calc(var(--fenix-navbar-height) + 0.5rem);
      }

      .athenaeum-language-dropdown-dropdown {
        span {
          color: black !important;
        }
        .athenaeum-language-dropdown-dropdown_open {
          top: calc(var(--fenix-navbar-height) + 0.5rem);
        }
      }
    }

    &:not([blended="false"]) {
      background-color: white !important;
      position: fixed !important;

      .athenaeum-top-nav-left img {
        filter: unset;
      }
      div.athenaeum-top-nav-left_backIcon {
        color: var(--primary);
        &:hover {
          color: var(--primary-hover);
        }
      }

      .athenaeum-top-nav-link {
        color: black !important;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          color: var(--primary) !important;
          background-color: transparent !important;
        }
      }

      div.athenaeum-top-nav-right_hamburgerIcon {
        border-color: black;
        i {
          color: black !important;
        }
        &:hover {
          color: black !important;
        }
      }

      ul.athenaeum-hamburger-hamburger,
      ul.athenaeum-hamburger-hamburger_open {
        top: calc(var(--fenix-navbar-height) + 0.5rem);
      }

      .athenaeum-language-dropdown-dropdown {
        span {
          color: black !important;
        }
        .athenaeum-language-dropdown-dropdown_open {
          top: calc(var(--fenix-navbar-height) + 0.5rem);
        }
      }
    }
  }

  .athenaeum-accordion-header {
    h3 {
      font-size: 18px;
    }
    font-size: 18px;
  }

  .accordionHeaderContainer {
    background-color: #F28325;
    box-shadow: none;
    cursor: pointer;
    
    h3 {
      color: white;
    }

    i {
      color: white;
    }
  }

  .athenaeum-top-nav-right {
    display: flex;
    position: relative !important;
  }

  /* Navigation links */

  .athenaeum-nav-link.active {
    background-color: var(--primary) !important;
  }

  .nav-link.active {
    background-color: var(--primary) !important;
  }

  /* Buttons */

  // Primary Button

  .btn-primary {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--light) !important;
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: $primary-disable-color !important;
    color: var(--light) !important;
  }
  .btn-primary:focus,
  .athenaeum-button-button.athenaeum-button-hover:focus {
    background-color: var(--primary) !important;
    color: var(--light) !important;
  }

  .btn-primary:hover,
  .athenaeum-button-button.athenaeum-button-hover:hover,
  .btn-primary:active,
  .athenaeum-button-button.athenaeum-button-hover:active {
    background-color: $primary-hover !important;
    border-color: $primary-hover !important;
    color: var(--light) !important;
  }

  // Info Button

  .btn-info {
    background-color: $info-color !important;
    color: var(--light) !important;
  }

  .btn-info.disabled,
  .btn-info:disabled {
    background-color: $info-disable-color !important;
    color: var(--light) !important;
  }

  .btn-info:hover {
    background-color: $info-hover-color !important;
    color: var(--light) !important;
  }

  // Success Button

  .btn-success {
    background-color: $success-color !important;
    color: var(--light) !important;
  }

  .btn-success.disabled,
  .btn-success:disabled {
    background-color: $success-disable-color !important;
    color: var(--light) !important;
  }

  .btn-success:hover {
    background-color: $success-hover-color !important;
    color: var(--light) !important;
  }

  // Danger Button

  .btn-danger {
    background-color: $danger-color !important;
    color: var(--light) !important;
  }

  .btn-danger.disabled,
  .btn-danger:disabled {
    background-color: $danger-disable-color !important;
    color: var(--light) !important;
  }

  .btn-danger:hover {
    background-color: $danger-hover-color !important;
    color: var(--light) !important;
  }

  // Warning Button

  .btn-warning {
    background-color: $warning-color !important;
    color: black !important;
  }

  .btn-warning.disabled,
  .btn-warning:disabled {
    background-color: $warning-disable-color !important;
    color: $dark-color !important;
  }

  .btn-warning:hover {
    background-color: $warning-hover-color !important;
    color: $dark-color !important;
  }

  // Secondary Button

  .btn-secondary {
    background-color: $secondary-color !important;
    color: var(--light) !important;
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    background-color: $secondary-disable-color !important;
    color: var(--light) !important;
  }

  .btn-secondary:hover {
    background-color: $secondary-hover-color !important;
    color: var(--light) !important;
  }

  // Link Button

  .btn-link {
    color: var(--primary) !important;
    border: none;
  }

  .btn-link:hover {
    color: $primary-hover !important;
  }

  // Light Button

  .btn-light {
    background-color: var(--light) !important;
    color: $dark-color !important;
  }

  .btn-light.disabled,
  .btn-light:disabled {
    background-color: $light-disable-color !important;
    color: $dark-color !important;
  }

  .btn-light:hover {
    background-color: $light-hover-color !important;
    color: $dark-color !important;
  }

  // Dark Button

  .btn-dark {
    background-color: $dark-color !important;
    color: var(--light) !important;
  }

  .btn-dark.disabled,
  .btn-dark:disabled {
    background-color: $dark-disable-color !important;
    color: var(--light) !important;
  }

  .btn-dark:hover {
    background-color: $dark-hover-color !important;
    color: var(--light) !important;
  }

  .athenaeum-button-button.athenaeum-button-color_orange {
    color: var(--light);
    background-color: var(--primary) !important;
  }

  .athenaeum-top-nav-navigation .athenaeum-top-nav-middle .athenaeum-top-nav-link {
    color: #606060;
  }
  
  .athenaeum-top-nav-middle {
    a {
      @media (min-width: $break-xl) {
        padding: 0.5rem !important;
      }
    }

    .athenaeum-top-nav-link:last-child {
      background-color: var(--primary);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      height: 50% !important;
      margin-right: 0.5rem;
      color: white !important;
      box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
      font-size: 1.1rem;
      
      &:hover {
        background-color: var(--primary-hover) !important;
        color: white !important;
      }
      
      &:focus {
        background-color: var(--primary-hover) !important;
        color: white !important;
      }
    }
  }

  .btn-primary {
    background-color: var(--primary) !important;
  }

  .athenaeum-link-link {
    font-family: var(--fenix-font-family-regular);
  }
  .athenaeum-link-link:hover {
    background-color: $primary-hover !important;
  }

  // Generic Button

  .athenaeum-button-button {
    border-radius: 11px !important;
  }

  .athenaeum-button-button.button-text {
    color: $secondary-color !important;
  }

  .athenaeum-button-button.athenaeum-button-text {
    color: $primary-hover !important;
  }

  /* Dropdown */

  .athenaeum-dropdown-dropdown {
    //margin: 0.5rem 0; //it breaks Forms
  }

  .athenaeum-language-dropdown-dropdown_open {
    background-color: var(--primary) !important;
  }

  .athenaeum-language-dropdown-dropdown_open li:hover {
    background-color: $primary-hover !important;
  }

  .athenaeum-dropdown-list .dropdown-itemsContainer .dropdown-item.dropdown-listItem {
    color: var(--primary) !important;
  }

  .athenaeum-dropdown-list .dropdown-itemsContainer .dropdown-item.dropdown-listItem.dropdown-selectedItem {
    color: $primary-hover !important;
  }

  .athenaeum-dropdown-dropdown .athenaeum-dropdown-selected {
    display: grid !important;
    grid-template-columns: auto 30px;
    padding: 0 !important;
    justify-content: space-between;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
    border-radius: 6px;

    i {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: solid 1px rgba($dark-color, 0.1);
    }

    span {
      padding: 3px 1rem;
    }
  }

  .athenaeum-dropdown-item {
    padding: 0.5rem !important;
    border-radius: 0 !important;
  }

  .athenaeum-dropdown-itemsContainer {
    //background-color: transparent !important;
    color: black !important;
    //box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .athenaeum-dropdown-item:hover {
    background-color: $primary-hover !important;
  }

  .athenaeum-dropdown-list .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-scrollableContainer {
    overflow-y: unset !important;
  }

  .athenaeum-dropdown-list .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-item {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-item,
  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-noResults {
    color: $dark-color !important;
    small {
      color: $dark-color !important;
    }
  }

  .athenaeum-dropdown-item:hover,
  .athenaeum-dropdown-noResults:hover {
    background-color: $light-hover-color !important;
    color: $dark-color !important;
  }

  .athenaeum-dropdown-list .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-selectedItem,
  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-selectedItem {
    background-color: #f283250d !important;
    color: #606060 !important;

    small {
      color: var(--light) !important;
    }
  }

  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer {
    padding-bottom: 1rem !important;
  }

  .athenaeum-button-actions.athenaeum-button-color_orange {
    background-color: white !important;
    color: $dark-color !important;
  }

  .athenaeum-button-actions.athenaeum-button-color_orange .athenaeum-button-action:hover {
    background-color: var(--primary) !important;
    color: var(--light) !important;
  }

  .athenaeum-dropdown-list .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-group:hover,
  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-group:hover,
  .athenaeum-dropdown-list .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-group:active,
  .athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer .athenaeum-dropdown-group:active {
    color: var(--primary) !important;
  }

  .athenaeum-dropdown-listItem {
    color: $dark-color !important;
  }

  .athenaeum-dropdown-listItem:hover {
    color: var(--light) !important;
  }

  /* Widget overrides */

  .athenaeum-widget-container-widget {
    //background-color: var(--light) !important;
    background-color: transparent !important;
  }

  .athenaeum-widget-container-widget a {
    background-color: var(--light) !important;
    color: $dark-color !important;
  }

  .athenaeum-widget-container-widget a:hover {
    background-color: $light-hover-color !important;
    color: $dark-color !important;
  }

  /*  Nullable Switch */

  .athenaeum-switch-nullableSwitch,
  .athenaeum-switch-nullableSwitchUnchecked,
  .athenaeum-switch-nullableSwitchChecked {
    height: 36px !important;
    border: none !important;
  }

  .athenaeum-switch-container .athenaeum-switch-nullableSwitchUnchecked .athenaeum-switch-toggler {
    left: 2px !important;
    background-color: var(--light) !important;
  }

  .athenaeum-switch-container .athenaeum-switch-nullableSwitchChecked .athenaeum-switch-toggler {
    left: calc(100% - 22px) !important;
    background-color: var(--light) !important;
  }

  .athenaeum-switch-nullableSwitch {
    background-color: var(--light) !important;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
  }

  .athenaeum-switch-nullableSwitchChecked {
    padding: 3px;
  }

  .athenaeum-switch-toggler {
    background-color: var(--primary) !important;
    padding: 0.3rem;
    height: 90% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  /* Checkbox */
  //  Had to cheat. since Checkbox is fontawesome png? icon. for filling all the checkbox with color had to set the background to primary color and put some padding and set
  //  the div around icon white so it can be seen from outside.

  .athenaeum-checkbox-checkbox {
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 0.1rem 0.5rem;
    color: var(--primary) !important;
    padding: 5px;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.25px solid #707070;
    transition: all 0.2s, background-color 0s;

    .fa-square {
      color: transparent;
    }

    i,
    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }

    i::before {
      font-size: 40px;
    }

    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .athenaeum-checkbox-checked {
    background-color: var(--primary);

    div {
      background-color: var(--light);
    }
  }

  .athenaeum-checkbox-readonly {
    background-color: $light-disable-color !important;
    box-shadow: 0px 3px 6px #00000029 !important;

    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .athenaeum-checkbox-readonly.athenaeum-checkbox-checked {
    div {
      background-color: $primary-disable-color;
    }

    i {
      color: $light-disable-color;
    }
  }

  .athenaeum-checkbox-readonly.athenaeum-checkbox-unchecked {
  }

  /* Input */

  .athenaeum-text-input-textInput {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
  }

  /* Confirmation Dialog */

  .athenaeum-confirmation-dialog-dialogContent {
    border-radius: 0 !important;
    padding: 2rem 3rem !important;

    h5 {
      margin-bottom: 2rem;
    }
  }

  .athenaeum-spinner-circle {
    border-color: var(--primary) !important;
    border-bottom-color: transparent !important;
  }

  /* HTML scrolling */

  .vertical-scroll {
    overflow-y: scroll;

    &.prevent-scroll {
      position: fixed;
      overflow-y: hidden !important;
      padding-right: 15px;

      nav {
        width: calc(100% - 15px);
      }

      .modal-open .modal {
        overflow-y: scroll;
      }
    }
  }

  /* Form-control override Bootstrap 4 font-size */

  .form-control {
    font-size: inherit;
  }

  /* Modal */

  .modal-open:not(.mobile) {
    padding-right: 0 !important;

    > div {
      > div {
        nav,
        main,
        footer {
          -webkit-filter: blur(1px);
          -moz-filter: blur(1px);
          -o-filter: blur(1px);
          -ms-filter: blur(1px);
          filter: blur(1px);
        }
      }
    }
  }

  .tests-page {
    .athenaeum-page-container-container {
      background-color: white !important;
    }
  }

  .modal .modal-dialog .modal-content .modal-header .toolbar .dismiss {
    background-color: var(--primary) !important;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: rgba(var(--primary), 0.1) !important;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(var(--primary), 0.3) !important;
  }

  .form-control {
    border-radius: 10px !important;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
    border: 3px solid #fff !important;
  }

  .form-control:focus {
    border-width: 3px;
    color: $dark-color !important;
    box-shadow: none !important;
    border-color: $secondary-color !important;
  }

  input:read-only {
    background-color: $light-disable-color !important;
    border-color: $light-disable-color !important;
  }

  /* Textarea */

  textarea:read-only {
    margin: 0 !important;
    background-color: $light-disable-color !important;
    border: 3px solid $light-disable-color !important;
    box-shadow: none !important;
    color: $dark-color !important;
  }

  textarea:read-only:focus {
    margin: 0 !important;
    background-color: $light-disable-color !important;
    border: 3px solid $light-disable-color !important;
    color: $dark-color !important;
  }

  /* Alert */

  .alert-success {
    background-color: $success-color !important;
    color: var(--light);
  }

  .alert-info {
    background-color: $info-color !important;
    color: var(--light);
  }

  /* Tooltip */

  .tooltip > .tooltip-inner {
    background-color: #007bff;
  }

  .tooltip > .arrow:before {
    border-top-color: #007bff;
  }

  /* Other overrides */

  .pac-container.pac-logo.hdpi {
    z-index: 10000 !important;
  }

  .no-first-last-margin {
    > div {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .no-first-last-padding {
    > div {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .athenaeum-footer-footer {
    //display: none;
  }

  .athenaeum-widget-container-widget {
    //display: none;
  }

  /* Breakpoints */
  /* Sizes */
  /* Colors */
  /* Hover colors */
  /* Shadows */
  /* Border */
  .athenaeum-top-nav-font-renta {
    font-family: "DINProMedium";
  }

  .athenaeum-top-nav-navigation {
    background-color: rgb(254, 80, 0);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    height: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left {
    display: flex;
    height: inherit;
    align-items: center;
    position: absolute;
    left: 0;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon {
    color: white;
    cursor: pointer;
    padding: 0 12px;
    margin-right: -8px;
    height: inherit;
    display: flex;
    align-items: center;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon.athenaeum-top-nav-noBackButton {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon.athenaeum-top-nav-noBackButton {
      display: flex;
      visibility: collapse;
      width: 0;
      cursor: default;
      pointer-events: none;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon i {
    font-size: 1.6em;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon:hover {
    background-color: rgb(234, 60, 0);
  }
  @media screen and (max-width: 480px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left_backIcon {
      margin-left: 0;
      margin-right: 0;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left_leftNav {
    color: white;
    cursor: pointer;
    text-align: center;
    min-width: 25px;
  }
  @media screen and (min-width: 480px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left_leftNav {
      min-width: 32px;
    }
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left_leftNav:not(:first-child) {
      padding-left: 6px;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left img {
    height: 32px;
    margin: 0 12px;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left img {
      height: 24px;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  @media screen and (max-width: 310px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left img {
      height: 16px;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-left span {
    font-size: 1.8em;
    color: white;
    opacity: 0.9;
    margin-bottom: -4px;
    white-space: nowrap;
  }
  @media screen and (max-width: 310px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-left span {
      font-size: 1.4em;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-middle {
    display: flex;
    height: inherit;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-middle .athenaeum-top-nav-link {
    padding: 0 16px;
    height: inherit;
    display: flex;
    align-items: center;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-middle .athenaeum-top-nav-link:hover {
    background-color: rgb(234, 60, 0);
  }
  @media screen and (max-width: 1520px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-middle .athenaeum-top-nav-link {
      display: none;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-middle .athenaeum-top-nav-link.athenaeum-top-nav-active {
    visibility: visible;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_search {
    margin-left: 6px;
    margin-right: 6px;
    color: #ffffff;
    cursor: pointer;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_shoppingCart {
    color: #ffffff;
    cursor: pointer;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_shoppingCartCount {
    font-size: 12px;
    background: #000000;
    color: #fff;
    vertical-align: top;
    margin-left: -10px;
    margin-top: -15px;
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_notifications {
    color: #ffffff;
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_notificationsCount {
    font-size: 12px;
    background: #000000;
    color: #fff;
    vertical-align: top;
    margin-left: -18px;
    margin-top: -15px;
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_infoIcon {
    color: white;
    margin: 0 12px;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-right_infoIcon {
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-right_hamburgerIcon {
    display: none;
    cursor: pointer;
    color: white;
    margin: 0 12px;
    width: 25px;
    z-index: 1;
  }
  @media screen and (max-width: 480px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-right_hamburgerIcon {
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  @media screen and (max-width: 1520px) {
    .athenaeum-top-nav-navigation .athenaeum-top-nav-right_hamburgerIcon {
      display: flex !important;
    }
  }
  .athenaeum-top-nav-navigation .athenaeum-top-nav-hover:hover, .athenaeum-top-nav-navigation .athenaeum-top-nav-right_hamburgerIcon:hover {
    color: black;
  }
}
