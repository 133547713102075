@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .loginModal {
    margin-left: 28px;
    margin-right: 28px;
  }
  
  .occupationalHealthInfoBox {
    background-color: var(--fenix-appointment-booking-background);
    padding: 2rem;
    display: flex;
    border-radius: var(--border-radious-xl);
    justify-content: center;
    justify-items: center;
    position: relative;
    margin-bottom: 1rem;
    color: var(--carbon);
  }

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();

      button {
        border: none;
      }

      .appointmentButton {
        cursor: pointer;
        overflow: hidden;
        grid-column: span 12;

        @media (min-width: $break-md) {
          grid-column: span 6;
        }
        @media (min-width: $break-lg) {
          grid-column: span 6;
        }
        @media (min-width: $break-xl) {
          grid-column: span 3;
        }
        @media (min-width: $break-2xl) {
          grid-column: span 3;
        }
      }

      .cancelAppointmentContainer {
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border-top: 1px solid var(--carbon);

        h5 {
          padding-bottom: 1rem;
          color: var(--carbon);
        }

        @media (max-width: $break-md) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
        
      }

      .reserveTimeCard {
        background-color: var(--fenix-appointment-booking-background);
        border-radius: 22px;
        padding: 2rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        .search {
          button {
            border-radius: var(--border-radious-sm);
            padding: 0 3rem;
          }
        }

        .cardHeader {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          font-size: 1.4rem;
          color: var(--carbon);
        }

        .reserveTimeNav {
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(12, 1fr);

          button {
            border: 2px solid var(--primary) !important;
            border-radius: var(--border-radious-xl) !important;
          }

          button:not(first-child) {
            border: 2px solid var(--primary) !important;
            background-color: transparent;
            color: var(--primary);

            &:hover {
              background-color: var(--primary) !important;
              color: white !important;
            }
          }
        }
      }
    }
  }
}
