@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-search-page-header-overrides(false);
    @include fenix-search-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
      padding-top: 1rem;
      padding-bottom: 1rem;

      h2, h5 {
        color: var(--primary);
      }
      
      .searchContainer {
        position: relative;
        top: -16rem;
        background: white;
        border-radius: 30px;
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
      }
      
      .searchBarDiv {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column: 1 / span 12;
        height: 4rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 1px;
        border-radius: var(--border-radious-md, 0.75rem);
        width: 100%;
        justify-content: center;
        padding: 0.5rem;
        gap: 1rem;
        
        input{
          grid-column: 1 / span 10;
          border: none;
          border-radius: var(--border-radious-md, 0.75rem);

          @media (max-width: $break-md) {
            grid-column: 1 / span 9;
          }
          
          &:focus, &:focus-visible{
            border: none;
            outline: none;
          }
        }

        .actionButton {
          padding: 1rem;
          background-color: var(--primary);
          color: var(--light);
          font-family: var(--fenix-font-family-semibold);
          border-radius: var(--border-radious-md, 0.75rem);
          font-size: 1.1rem !important;
          border: unset;
          justify-content: center;
          grid-column: 11 / span 2;

          @media (max-width: $break-md) {
            grid-column: 10 / span 3;
          }

          &:hover,
          &:focus,
          &:focus-within {
            background-color: var(--primary-hover) !important;
            color: white !important;
          }

          & > span {
            white-space: pre-wrap;
            text-align: left;
            @media (min-width: $break-lg) {
              white-space: unset;
            }
          }

          i {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1rem 0 0;
          }
        }
        
        
      }
      
      .filterBarDiv {
        margin-top: 2rem;
        grid-column: 1 / span 12;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: $break-md) {
          flex-direction: column;
          gap: 1rem;
        }
        
        .paginationDiv {
          display: flex;
          flex-direction: row;
          justify-content: center;
          justify-items: center;
          gap: 1rem;
        }
      }

      .paginationButton {
        border: none;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
        width: 2.5rem;
        height: 2.5rem;
      }
      
      .paginationButton:disabled {
        color: var(--light) !important;
      }
      
      .searchResultsContainer {
        margin-top: 1rem;
        grid-column: 1 / span 12;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        .searchResultsHeader {
          border-bottom: 1px solid lightgray;
        }
        
        .searchResultsHitsContainer {
          min-height: 5vh;
          
          .loadingContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            padding-top: 2rem;
          }
          
          .searchResultsHits{
            
          }
          
          .bottomPaginationDiv{
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            justify-items: center;
            gap: 1rem;

            @media screen and (max-width: $break-md) {
              justify-content: center;
              gap: 1rem;
            }
          }
        }
        
      }
      
    }
  }
}