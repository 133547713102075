@import "../../theme/variables";

.container {
  display: block !important;
  position: relative;
  margin: 0 auto;
  padding: 1rem 0;
  min-width: 20px;
  min-height: 20px;

  ._absolute_center {
    position: absolute;
    transform-origin: center;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .spinnerBackground {
    @extend ._absolute_center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid white;
    animation: spin 4s infinite linear;
  }

  .spinnerCircle {
    @extend ._absolute_center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    border-bottom-color: transparent;
    animation: spin 0.8s infinite linear;
  }

  @keyframes spin {
    from {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }
}
