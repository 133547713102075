@import "./variables";
@mixin fenix-page-container-overrides() {
  //  Big bang
  &,
  :global(.athenaeum-page-container-content),
  :global(.athenaeum-page-container-content > div > h4),
  :global(.athenaeum-page-container-page),
  :global(.athenaeum-page-container-container) {
    margin: 0 !important;
    padding: 0 !important;
    display: unset !important;
  }

  :global(.athenaeum-page-container-header hr) {
    display: none;
  }
  :global(.athenaeum-page-container-container) {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
  }
  :global(.athenaeum-page-container-header) {
    order: 1;
  }
  :global(.athenaeum-page-container-alertRow) {
    order: 2;
  }
  :global(.athenaeum-page-container-row) {
    //  Remove affect of CL's media query
    @media screen and (max-width: 768px) {
      margin: unset !important;
    }
  }
}

@mixin fenix-home-page-header-overrides($wide) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--fenix-app-home-header-background);
  background-size: cover;
  gap: 1rem;
  margin: 0 !important;
  padding: 1rem !important;

  @media (max-width: $break-lg) {
    background: var(--fenix-app-home-header-background-mobile);
    background-size: cover;
  }
  
  @if $wide == true {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;
    }
  }
  @if $wide == false {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }
    }
  }
}

@mixin fenix-search-page-header-overrides($wide) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--fenix-app-home-header-background);
  background-size: cover;
  gap: 1rem;
  margin: 0 !important;
  padding: 1rem !important;
  height: 30rem;

  @media (max-width: $break-lg) {
    background: var(--fenix-app-home-header-background-mobile);
    background-size: cover;
  }

  @if $wide == true {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;
    }
  }
  @if $wide == false {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }
    }
  }
}

/// @param {boolean} $wide
@mixin fenix-page-header-overrides($wide) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--fenix-app-header-background);
  background-size: cover;
  gap: 1rem;
  margin: 0 !important;
  padding: 1rem !important;
  @if $wide == true {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;
    }
  }
  @if $wide == false {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }
    }
  }
}
@mixin fenix-service-page-header-overrides($wide) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--fenix-app-recent-header-background);
  background-size: cover;
  gap: 1rem;
  margin: 0 !important;
  padding: 1rem !important;
  @if $wide == true {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;
    }
  }
  @if $wide == false {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }
    }
  }
}
@mixin fenix-digiClinic-page-header-overrides($wide) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--fenix-app-digiClinic-header-background);
  background-size: cover;
  gap: 1rem;
  margin: 0 !important;
  padding: 1rem !important;
  @if $wide == true {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;
    }
  }
  @if $wide == false {
    :global(.athenaeum-page-container-content) {
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }
    }
  }
}
@mixin fenix-page-header-text() {
  padding-top: calc(4rem + var(--fenix-navbar-height)) !important;
  padding-bottom: 4rem !important;
  h4 {
    color: var(--light);
    font-family: var(--fenix-font-family-medium);
    font-size: clamp(2rem, 4vw, 2.5rem) !important;
  }
}

@mixin fenix-search-page-header-text() {
  padding-top: calc(4rem + var(--fenix-navbar-height)) !important;
  padding-bottom: 4rem !important;
  h4 {
    color: var(--dark);
    font-family: var(--fenix-font-family-medium);
    font-size: clamp(2rem, 4vw, 2.5rem) !important;
  }
}

/// @param {boolean} $wide set to true if whole width is needed.
@mixin fenix-page-content-overrides($wide: true, $disablePadding: false) {
  order: 3;
  @if $disablePadding == false {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @if $wide == false {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
  }
}
@mixin fenix-page-content-fluid-grid-child() {
  grid-column: 1 / span 12;

  @media (min-width: $break-lg) {
    grid-column: 1 / span 12;
  }
  @media (min-width: $break-xl) {
    grid-column: 2 / span 10;
  }
  @media (min-width: $break-2xl) {
    grid-column: 3 / span 8;
  }
}
