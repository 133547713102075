@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .container{
    @media screen and (max-width: $break-md){
      flex-direction: column;
      >div{
        width: 100% !important;
      }
    }
    display: flex;

    h2{
      margin-top: 40px;

      font-size: 32px;
      margin-bottom: 16px;
    }
    i{
      color: $primary;
    }

    .box:last-child{
      padding:0;

      border-top-right-radius:20px;
      border-bottom-right-radius:20px;
    }

    .box{
      border-top-left-radius:20px;
      border-bottom-left-radius:20px;

      padding:40px;
      width: 50%;

      input{
        border: 1px solid #606060 !important;
      }

      .button{
        height: 52px;
        width: 100%;
      }
      .half{
        @media (min-width: $break-xxs) {
          width: 100%;
        }

        @media (min-width: $break-sm) {
          width: 50%;
        }
        
      }
    }
    .phoneTimesContainer{
      display: flex;
      justify-content: space-between;
    }

    .locationsContainer{
      display: flex;
      flex-direction: column;
      h6{
        color: $primary;
        margin-bottom: 0;
      }
      >div{
        margin-top:20px
      }
    }
  }
  .openingHoursTextDiv {
    margin-top: 0.5rem;
    font-weight: bold;
    color: #606060;
  }
  
  .phoneNumber {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    
    a{
      font-weight: bold;
    }
  }

  .locationOpeningHours {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @media (min-width: $break-xxs) {
      gap: 1.2rem;
    }

    @media (min-width: $break-sm) {
      gap: 2rem;
    }
    .openingHoursTable{
      column-count: 2;
    }
    .openingHoursRow {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      & > span:first-child {
        font-weight: bold;
        min-width: 22px;
        color: var(--carbon);
      }
    }

    .openingHoursColumn {
      display: flex;
      flex-direction: column;

      & > span:first-child {
        font-weight: bold;
        color: #606060;
      }

    }
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();


    }
  }
}
