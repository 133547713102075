@import "../../theme/variables";

.container {
  margin-top: 1rem;
  background-color: #b99169;
  .content {
    max-width: $break-lg;
    padding: 5rem 1rem;
    color: var(--light);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;

    button,
    a {
      color: var(--light) !important;
      font-weight: bold;
      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        color: var(--carbon) !important;
        background-color: transparent !important;
      }
    }
    
    .locationAddress {
      & > span:first-child {
        margin-right: 0.3rem;
      }
    }

    .locations {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));

      .location {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;

        & > div:first-child {
          font-weight: bold;
        }

        & > div:last-child {
          display: flex;
          gap: 0.3rem;
        }
        
        .locationName {
          text-align: left;
          white-space: break-spaces;
        }
      }
    }

    .phoneNumber {
      display: flex;
      gap: 0.1rem;
      align-items: center;
      margin-top: 0.5rem;

      & > span:first-child {
        padding-right: 0.5rem;
      }

      & > span:nth-child(2) {
        padding-right: 0.2rem;
      }

      & > span:last-child {
        font-weight: bold;
        a {
          text-decoration: none;
          color: var(--light);
        }
      }
    }

    .openingTimes {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(100px, min-content));

      .openingTime {
        & > div:first-child {
          font-weight: bold;
        }
      }
    }
    .action {
    }
  }
  
  .locationOpeningHours {
    display: flex;
    flex-direction: column;
    gap: 0.5rem !important;
    margin-top: 0.3rem;

    & > div:nth-child(2) {
      font-size: 14px;
    }
    
    .openingHoursTable{
      column-count: 2;
    }
    
    .openingHoursRow {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      font-size: 14px;

      & > span:first-child {
        font-weight: bold;
        min-width: 22px;
      }
    }
  }
}
