@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .serviceAccordion {
    border: 0.25px solid #606060;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .selectList {
    height: 500px;
    color: #f283250d;
  }

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .innerListItem {
    margin-left: 20px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border: unset;
    background-color: unset;
    text-align: left;
    width: 100%;
    border-radius: var(--border-radious-sm);
  }

  .selected {
    & > div:first-child {
      background-color: #f283250d !important;
      color: #606060 !important;
    }
  }
  

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
  }

  .listItemAccordion {
    box-shadow: unset !important;
    position: relative;

    :global(.athenaeum-accordion-header) {
      padding: 0;
      &:hover {
        background-color: unset !important;
      }
    }
    :global(.athenaeum-accordion-headerContainer) {
      &:hover {
        background-color: #f283250d; /* Change the color and opacity as desired */
      }
    }

    :global(.athenaeum-accordion-separator) {
      display: none !important;
    }

    :global(.athenaeum-accordion-content) {
      padding: 0 !important;
    }

    .accordionListWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem 0;
    }
  }

  .accordionHideArrow {
    :global(.athenaeum-accordion-expansionInfo) {
      display: none !important;
    }
  }

  .clinicAccordion {
    border: 0.25px solid rgba(96, 96, 96, 0.3);
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: none;

    .accordionHeaderContainer {
      cursor: pointer;
    }

    i {
      color: #f28325;
    }

    hr {
      display: none;
    }
  }
  
  
}
