@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-digiClinic-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);
    
    .appStoreDiv {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      gap: 1.5rem;
      height: 4rem;

      @media (max-width: $break-360px) {
        flex-direction: column;
        height: auto;
      }

      @media (min-width: $break-360px + 1) {
        height: 3rem;
      }

      @media (min-width: $break-lg) {
        height: 4rem;
      }
      
      img {
        cursor: pointer;

        @media (max-width: $break-360px) {
          width: 9rem;
        }

        @media (min-width: $break-360px) {
          height: 100%;
        }
        
      }
    }
    
    .linkHeader{
      div:hover{
        background-color: rgb(255, 250, 246) !important;
        cursor: pointer;
      }
      
    }
    
    .headerIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
    
    [aria-expanded="true"]{
      background-color: rgb(255, 250, 246) !important;
    }

    .digiAccordionsDiv {
      button:hover, button:focus, button:active {
        background-color: rgb(255, 250, 246) !important;
      }
    }
    
    .headerDiv{
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }
    
    .introDiv {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;

      @media (max-width: $break-md) {
        flex-direction: column-reverse;
        height: auto;
        justify-content: center;
      }
      
      .introTextDiv {
        display: flex;
        flex-direction: column;
        width: 50%;

        @media (max-width: $break-md) {
          width: 100%;
          justify-content: center;
        }
        
        .introLinksDiv {
          display: flex;
          flex-direction: column;
          width: 80%;

          @media (max-width: $break-lg) {
            grid-column: 1 / span 12;
            width: 100%;
            margin: auto;
          }
          
          .desktopLinkDiv{
            display: flex;
            margin-bottom: 1rem;
            
            .desktopLinkButton {
              display: flex;
              height: 3rem;
              justify-content: center;
              width: 100%;
            }
          }
          
          .appLinksDiv{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            @media (max-width: $break-400px) {
              flex-direction: column;
            }
            
            .appLinkButtonDiv {
              display: flex;
              flex-direction: row;
              justify-content: center;

              @media (max-width: $break-400px) {
                width: 100%;
                padding: 0.5rem;
              }
              

              @media (min-width: $break-400px) {
                width: 80%;
              }


              img {
                cursor: pointer;
                height: 100%;

                @media (max-width: $break-400px) {
                  min-width: 60%;
                }
              }
            }
            
            
            
          }
        }
      }
      
      .introImgDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        max-width: 50%;

        @media (max-width: $break-md) {
          max-width: 100%;
          margin-bottom: 2rem;
          padding: 2rem;
        }
        
        img {
          max-height: 100%;
          width: 100%;
        }
      }
    }
    
    .mainAccordionHeader{
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      justify-items: center;
      height: 2.5rem;
      text-align: center;
    }
    
    .basicAccordionHeader{
      color: var(--primary);
      font-weight: bold;
    }

    .contentDiv {
      margin-bottom: 2rem;
    }
    
    
    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
      padding-top: 1rem;
      padding-bottom: 1rem;

      hr {
        border-radius: 50px;
        border-top: 5px solid var(--primary);
      }

      .digiAccordionIcon {
        img {
          height: 2rem;
        }
        
      }
      
      .rowStack{
        display: flex;
        flex-direction: row;
      }

      .digiAccordion {
        margin-top: 20px;
        box-shadow: none;
        border-radius: 13px;

        hr {
          display: none;
        }
      }
      
    }
  }
}