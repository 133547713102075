@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();

      .salesPitch {
        margin-top: 2rem;
        
        h2 {
          font-size: 32px;
        }

        .salesPitchContainer {
          display: flex;
          margin-top: 3rem;
          margin-bottom: 3rem;

          @media screen and (max-width: $break-md) {
            flex-direction: column;
            img {
              margin: auto;
            }
          }

          .salesPitchImgContainer {
            display: flex;
          }

          .salesPitchContent {
            width: 100%;
          }

          .salesPitchContactInfo {
            display: flex;
            justify-content: center;
            margin-left: 3rem;

            h3 {
              margin-bottom: 0;
            }

            @media screen and (max-width: $break-md) {
              flex-direction: column;
              margin-left: 0;
            }

          }
        }
      }

      .occupationalHealthTeamRosterTitle {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: auto;
        margin-top: 2rem;

      }

      .doctorsContainer {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: $break-md) {
          flex-direction: column;
          margin-left: 0;
        }

      }

      .doctor {
        .titleContainer {
          h3{
            margin-top:0;
            margin-bottom: 0;
            text-align: center;
          }

          cursor: pointer;
          display: flex;
          align-items: center;
          margin-top: 20px;
          flex-direction: column;
          min-height: 100px;
        }
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-basis: calc(100% / 3 - 40px);
        margin: 20px;

        .doctorImage {
          cursor: pointer;
          border-radius: 100%;
          width: 175px;
          height: 175px;
          object-fit: cover;
          object-position: center top;
        }
      }

      .salesGuy{
        border-radius: 100%;
        width: 175px;
        height: 175px;
        object-fit: cover;
        object-position: center top;
        margin: auto;
      }

      .appointmentButtonDiv {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}
