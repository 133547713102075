@import "../../../theme/variables";

.container {
  :global(.athenaeum-dropdown-dropdown .athenaeum-dropdown-selected i) {
    border-left: unset;
  }
  border-radius: 22px;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: flex-end;
  white-space: nowrap;

  @media (min-width: $break-md) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: $break-lg) {
    grid-template-columns: repeat(17, 1fr);
  }

  input:read-only {
    border: 1px solid black !important;
    box-shadow: none !important;
  }

  .dropdownInput {
    
    & :global(.athenaeum-dropdown-dropdown .athenaeum-dropdown-itemsContainer) {
      background-color: var(--light) !important;
    } 
    :global(.athenaeum-dropdown-dropdown .athenaeum-dropdown-selected){
      font-size: 1rem;
      border: 1px solid black !important;
      box-shadow: none !important;
    }
    @media (min-width: $break-md) {
      grid-column: span 2;
    }
    @media (min-width: $break-lg) {
      grid-column: span 3;
    }
  }

  .groupedInputContainer {
    display: flex;
    flex-direction: column;

    @media (min-width: $break-md) {
      grid-column: span 3;
    }
    @media (min-width: $break-lg) {
      grid-column: span 4;
    }
    input:read-only {
      background-color: white !important;
    }
    .groupedInput {
      display: flex;
      gap: 0.5rem;
      & > div {
        flex: 1;
      }
    }
  }

  .debugContainer {
    height: 300px;
    overflow-y: scroll;
    @media (min-width: $break-md) {
      grid-column: span 6;
    }
    @media (min-width: $break-lg) {
      grid-column: span 17;
    }
  }
}
