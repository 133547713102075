@import "../../theme/variables";
@import "../../theme/mixins";

.digiClinicFeature {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media(min-width: $break-md){
    gap: 3rem;
  }
}

.featureIconContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .featureIconDiv {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
  }

}

.featureTextContainer {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;

  .featureHeader {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--carbon);
  }
}