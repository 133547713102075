.pillButtonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  button{
    border-radius:25px !important;
  }
  :global(button.btn-primary) {
    border-width: 2px;
    border-color: var(--primary) !important;

  }
  :global(button:not(.btn-primary)) {
    background-color: transparent !important;
    border-color: var(--primary) !important;
    color: var(--primary) !important;
    border-width: 2px;
  }
  .controlPanel {
    position: relative;
    &[data-can-show-more=false] {
      display: none;
    }
    ._absolute_center {
      position: absolute;
      transform-origin: center;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .showMore {
      text-align: center;
      color: var(--primary) !important;
      font-weight: bold;
      text-transform: uppercase;
      &:hover, &:focus {
        background-color: unset !important;
        color: var(--primary-hover) !important;
      }
    }
  }
}