@import "../../theme/variables";

.confirmModal {
  
  .missingInfoText{
    color: darkred;
  }
  
  .appointmentInfoDiv{
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 0.2rem;

    @media (max-width: $break-md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .checkInfoTextDiv{
    color: var(--carbon);
    font-size: 0.9rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .missingInfoMessageDiv{
    p{
      span{
        color: darkred;
      }
    }
  }
  
  .editContactInfoButtonDiv{
    margin-top: 0.5rem;
  }
  
  .headerSpan{
    margin-bottom: 0.5rem !important;
    font-weight: bold;
    color: var(--carbon);
  }
  
  .confirmationBottomButtonDiv{
    
  }
  
  .bottomButtonDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;

    :global(button.btn-primary) {
      span{
        font-weight: bold;
      }
    }
    
    :global(button:not(.btn-primary)) {
      background-color: transparent !important;
      border-color: var(--primary) !important;
      color: var(--primary) !important;
      border-width: 2px;
      grid-column: span 12;

      span{
        color: var(--carbon) !important;
        font-weight: bold;
      }

      &:hover,
      &:focus,
      &:focus-within {
        background-color: var(--primary) !important;
        color: white !important;
        
        span{
          color: white !important;
        }
      }

    }
  }

  .validationError{
    color: red;
  }

  >div{
    max-width: 700px;
    
    >div{
        padding: 1rem;
      
      >div{
        >div{
          color: var(--carbon);
          h5, h6{
            font-weight: bold;
          }
        }
      }
    }
  }
  .confirmContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
    gap: 1.5rem;

    @media (max-width: $break-md) {
      grid-template-columns: 1fr;
    }

    p {
      margin: 0;
    }
  }
}