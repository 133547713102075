@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .container {
    @media screen and (max-width: $break-md) {
      flex-direction: column;
      > div {
        width: 100% !important;
      }
    }
    display: flex;

    h2 {
      margin-top: 40px;
      font-size: 32px;
      margin-bottom: 16px;
    }

    i {
      color: $primary;
    }

    .phoneOrEmailIcon {
      margin-right: 0.5rem;
    }

    .box {
      padding: 40px;
      width: 50%;

      input {
        border: 1px solid #606060 !important;
      }

      .button {
        height: 52px;
        width: 100%;
      }

      .widthUnset {
        width: unset;
      }
    }
    
    .contactSupportHeader{
      display: flex;
      justify-content: center;
    }

    .phoneTimesContainer {
      display: flex;
      justify-content: space-between;
    }

    .locationsContainer {
      display: flex;
      flex-direction: column;

      .location {
        margin-bottom: 1rem;
      }

      h6 {
        color: $primary;
        margin-bottom: 0;
        cursor: pointer;
      }

      > div {
        margin-top: 20px;
      }
    }
  }
  
  .contactPageImageDiv{
    display: flex;
    margin-bottom: 2rem;
    padding: 1rem;
    
    img{
      width: 100%;
      border-radius: var(--border-radious-lg);
    }
  }
  
  .contactPageImageTextContainer {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    border-radius: 15px;
    padding: 1rem;
    
    .contactPageImageHeaderDiv {
      width: 100%;
      h3 {
        margin-top: 1rem;
      }
    }

    .contactPageImageContentTexDiv {
      width: 100%;
    }
  }
  
  .phoneReservationContainer{
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $break-400px) {
      flex-direction: column;
    }
    
    & > div:first-child {
      justify-items: start;
    }
  }
  
  .phoneReservationRowContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    
    .phoneReservationRow {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      
      span {
        color: var(--carbon);
      }
    }
  }
  
  .openingHoursTextDiv {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .locationOpeningHours {
    display: flex;
    flex-direction: row;
    gap: 1.2rem !important;
    margin-top: 0.3rem;

    .openingHoursColumn {
      display: flex;
      flex-direction: column;
    }
  }

  .openingHoursContainer {
    & > div:nth-child(2) {
      font-size: 14px;
    }
  }

  .openingHoursTable{
    column-count: 2;
  }
  .phoneNumber {
    display: flex;
    gap: 0.1rem;
    align-items: center;
    margin-top: 0.5rem;

    & > span:first-child {
      padding-right: 0.5rem;
    }

    & > span:nth-child(2) {
      padding-right: 0.2rem;
    }
  }
  

  .openingHoursRow {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    & > span:first-child {
      font-weight: bold;
      min-width: 22px;
      color: var(--carbon);
    }
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
    }
  }
}
