@import "../../../theme/variables";

.mobileListItemDiv {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  grid-column: 1 / span 12;
  border-top: 1px solid black;
  border-bottom: 1px solid transparent;
  padding: 1rem;

  &[data-is-last-item-in-visible-list="true"] {
    border-bottom: 1px solid black;
  }

  .profileDiv {
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .profileDiv2 {
    grid-column: 5 / span 8;
  }

  .mobileTimeDiv {
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: column;
    margin: auto;
    
    .mobileTimeInnerDiv {
      text-align: start;
    }
    
    .timeAndDurationDiv {
      font-size: 0.7rem;
      font-weight: bold;
      color: var(--carbon);
      
      div:first-child {
        div:first-child {
          font-size: 0.9rem;
        }
      }
    }
  }

  .mobileButtonDiv {
    grid-column: 5 / span 8;

    button {
      width: 100%;
    }

  }

}

.listItemContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  border-top: 1px solid black;
  border-bottom: 1px solid transparent;

  @media (min-width: $break-md) {
    grid-template-columns: auto 1fr auto;
    gap: 2rem;
  }

  &[data-is-last-item-in-visible-list="true"] {
    border-bottom: 1px solid black;
  }

  //  Time And Duration Container
  & > div:nth-child(1) {
    margin: 0 auto;
  }

  //  Profile Container
  & > div:nth-child(2) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    align-items: center;
    @media (min-width: $break-md) {
      grid-column: unset;
      grid-row: unset;
    }
  }

  .duration {
    font-size: 0.8rem;
  }
  
  .appointmentType {
    font-weight: bold;
    font-size: 0.7rem;
    padding-left: 57px;
    color: var(--carbon);
  }
}

.container {
  background-color: var(--fenix-appointment-booking-background);
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: var(--border-radious-xl);

  .cardHeader {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    color: var(--carbon);

    & > div:first-child {
      font-size: 1.2rem;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    & > div:last-child {
      font-size: 1.5rem;

      font-weight: bold;
    }
  }

  .pillButtonGroup {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .date {
    .dateText{
      justify-content: center;
      display: flex;
      width: 200px;
    }
    font-weight: bold;
    font-size: 1.3rem;
    color: var(--carbon);
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
      background-color: transparent;
      color: var(--primary);
      border-color: var(--primary) !important;
      border-width: 2px;
      padding: 0;
      min-width: unset;
      min-height: unset;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
        font-weight: bold;
      }
    }
  }
}

.appointmentType {
  font-weight: bold;
  font-size: 0.7rem;
  color: var(--carbon);
}
