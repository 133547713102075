@import "../../theme/variables";
@import "../../theme/mixins";

.pageContainer {
  @include fenix-page-container-overrides();

  .pageHeader {
    @include fenix-page-header-overrides(false);
    @include fenix-page-header-text();
  }

  .pageContent {
    @include fenix-page-content-overrides(false);

    .fluidContent {
      @include fenix-page-content-fluid-grid-child();
      
      .jobPostingDiv {
        padding: 1rem;
      }

      .box {
        width: 65%;
        margin-top: 3rem;

        @media (min-width: $break-md) {
          padding: 40px;
          width: 65%;
          border-radius: 1rem;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px;
        }
        

        input {
          border: 1px solid #606060 !important;
        }

        .button {
          height: 52px;
          width: 100%;
          grid-column: span 12;
        }

        .widthUnset {
          width: unset;
        }
      }
      
      .textButton {
        text-align: center;
        color: var(--primary) !important;
        font-size: 1rem !important;
        font-weight: bold;
        &:hover, &:focus {
          background-color: unset !important;
          color: var(--primary-hover) !important;
        }
      }

      a {
        color: var(--primary) !important;
        font-weight: bold;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          color: var(--primary-hover) !important;
          background-color: transparent !important;
        }
      }
      
      .bottomDivider {
          border-bottom: 1px solid black;
          bottom: 0;
          left: 0;
          right: 0;
      }
      
      .jobPostingContactDiv {
        padding: 0.5rem;
        text-align: center;
        white-space: normal;
        word-break: break-word;
        display: flex;
        justify-content: center;

        @media screen and (max-width: $break-md) {
          > div {
            width: 100% !important;
          }
        }
        
      }
      
      .formNameFields {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        grid-column: 1 / span 12;
        
        .nameInput {
          grid-column: span 12;

          @media (min-width: $break-sm) {
            grid-column: span 6;
          }
        }
        
        .wideInput {
          grid-column: span 12;
        }
      }
      
      .jobApplicationFormTip {
        grid-column: span 12;
        
        h3{
          font-size: 1rem;
          margin-top: 0;
        }
      }
      
      .jobPostingBackButtonDiv {
        margin-bottom: 0.5rem;
      }
      
    }
  }

}