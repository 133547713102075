@import "../../theme/variables";
@import "../../theme/mixins";

.homePageContainer {
  @include fenix-page-container-overrides();

  .homePageHeader {
    @include fenix-home-page-header-overrides(true);
  }

  //  Structural css

  .infoBoxPrimary{
    background-color: var(--primary)
  }
  
  .infoBoxDanger{
    background-color: var(--danger)
  }
  
  .infoBoxSuccess{
    background-color: var(--success)
  }

  .pageContent {
    @include fenix-page-content-overrides(false, true);

    .fluidContent {
      grid-column: 1 / span 12;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .influenzaCard {
        grid-column: 1 / span 12;

        @media (min-width: $break-lg) {
          grid-column: 1 / span 4;
        }
        @media (min-width: $break-xl) {
          grid-column: 2 / span 4;
        }
        @media (min-width: $break-2xl) {
          grid-column: 3 / span 3;
        }
      }
      
      .cardAndBookingContainer {
        grid-column: 1 / span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        
      }
      
      .homeCardContainer {
        grid-column: 1 / span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        
        .homePageCardContainer {
          grid-column: 1 / span 12;
          display: flex;

          @media (max-width: $break-lg) {
            order: 2;
          }

          @media (min-width: $break-lg) {
            grid-column: 1 / span 4;
            order: 1;
          }
          @media (min-width: $break-xl) {
            grid-column: 2 / span 4;
          }
          @media (min-width: $break-2xl) {
            grid-column: 3 / span 3;
          }
        }
        
        .phoneBookingWidgetContainer {
          grid-column: 1 / span 12;

          @media (max-width: $break-lg) {
            order: 3;
          }

          @media (min-width: $break-lg) {
            grid-column: 1 / span 4;
            order: 3;
          }
          @media (min-width: $break-xl) {
            grid-column: 2 / span 4;
          }
          @media (min-width: $break-2xl) {
            grid-column: 3 / span 3;
          }
        }
        
        .bookingWidgetContainer {
          grid-column: 1 / span 12;

          @media (max-width: $break-lg) {
            order: 1;
          }

          @media (min-width: $break-lg) {
            grid-column: 5 / span 8;
            order: 2;
          }
          @media (min-width: $break-xl) {
            grid-column: 6 / span 6;
          }
          @media (min-width: $break-2xl) {
            grid-column: 6 / span 5;
          }
        }
        
        .fenixSportCardContainer {
          grid-column: 1 / span 12;

          @media (max-width: $break-lg) {
            order: 4;
          }

          @media (min-width: $break-lg) {
            grid-column: 5 / span 8;
            order: 4;
          }
          @media (min-width: $break-xl) {
            grid-column: 6 / span 6;
          }
          @media (min-width: $break-2xl) {
            grid-column: 6 / span 5;
          }
        }
        
      }
      
      .homePageCardAndPhoneBookingContainer {
        grid-column: 1 / span 12;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: $break-lg) {
          order: 2;
        }

        @media (min-width: $break-lg) {
          grid-column: 1 / span 4;
        }
        @media (min-width: $break-xl) {
          grid-column: 2 / span 4;
        }
        @media (min-width: $break-2xl) {
          grid-column: 3 / span 3;
        }
      }

      .bookingAndFenixSportContainer {
        grid-column: 1 / span 12;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: $break-lg) {
          order: 1;
        }

        @media (min-width: $break-lg) {
          grid-column: 5 / span 8;
        }
        @media (min-width: $break-xl) {
          grid-column: 6 / span 6;
        }
        @media (min-width: $break-2xl) {
          grid-column: 6 / span 5;
        }
      }
      
      .bookingWidget {
        
      }

      /*.bookingWidget {
        grid-column: 1 / span 12;

        @media (max-width: $break-lg) {
          order: 1;
        }

        @media (min-width: $break-lg) {
          grid-column: 5 / span 8;
        }
        @media (min-width: $break-xl) {
          grid-column: 6 / span 6;
        }
        @media (min-width: $break-2xl) {
          grid-column: 6 / span 5;
        }
      }*/
    }

    .wideContent {
      grid-column: 1 / span 12;
    }
  }
  
  .fenixSportContainer {
    background-color: var(--fenix-appointment-booking-background);
    padding: 4rem 4rem;
    display: grid;
    gap: 2rem;
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);
    border-radius: var(--border-radious-xl);
    justify-content: center;
    justify-items: center;
    
    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }

    @media (max-width: $break-lg) {
      display: none;
    }
    
    .fenixSportHeaderContainer {
      display: flex;
      width: 100%;
      grid-column: 1 / span 12;
      
      .fenixSportHeader {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--carbon);
      }
    }
    
    .fenixSportTextAndButtonContainer {
      grid-column: 1 / span 8;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      
      .fenixSportContent {
        font-size: 0.9rem;
        white-space: break-spaces;
      }
    }
    
    
    
    .fenixSportTextContainer {
      display: flex;
      flex-direction: column;
      grid-column: 2 / span 10;
      white-space: break-spaces;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 8;
      }

      @media (min-width: $break-xl) {
        grid-column: 1 / span 8;
      }
      @media (min-width: $break-2xl) {
        grid-column: 1 / span 8;
      }
      
      .fenixSportButtonContainer{
        padding-top: 2rem;

        .fenixSportButton {
          border-radius: 10px;
          border: none;
          min-width: 30%;
        }
      }

      .fenixSportHeader {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--carbon);
      }

      .fenixSportContent {
        padding-top: 2rem;
      }
    }
    
    .fenixSportImgContainer {
      display: flex;
      grid-column: 2 / span 10;
      justify-content: center;
      justify-items: center;
      
      img {
        object-fit: contain;
        width: 120%;
        
        @media (min-width: $break-lg) {
          margin-top: -4rem;
        }

        @media (min-width: $break-xl) {
          margin-top: -2rem;
        }
      }

      @media (min-width: $break-lg) {
        grid-column: 9 / span 4;
      }

      @media (min-width: $break-xl) {
        grid-column: 9 / span 4;
      }
      @media (min-width: $break-2xl) {
        grid-column: 9 / span 4;
      }
    }
  }
  
  .fenixSportSecondContainer {
    @extend .fenixSportContainer;
    display: grid;
    
    @media (min-width: $break-lg) {
      display: none;
    }
  }
  
  .infoBox{
    padding: 1rem;
    display: grid;
    gap: 2rem;
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);
    border-radius: var(--border-radious-xl);
    justify-content: center;
    justify-items: center;
    color: white;
    position: relative;

    @media (min-width: $break-xl) {
      grid-column: 2 / span 10;
    }
    @media (min-width: $break-2xl) {
      grid-column: 3 / span 8;
    }

   .infoBoxIconDiv {
     grid-column: 1 / span 12;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     width: 100%;
     height: 100%;

     @media (min-width: $break-md) {
       grid-column: 1 / span 2;
     }
   }
    
    .infoBoxTextDiv {
      grid-column: 1 / span 12;
      width: 100%;
      text-align: left;

      div:first-child {
        /*font-weight: bold;
        font-size: 2rem;
        color: white;*/
        margin-bottom: 0.3rem;
        font-size: 1.2rem;
      }

      div:last-child {
        white-space: break-spaces;
        font-size: 1.1rem;
      }

      @media (min-width: $break-xxs) {
        grid-column: 2 / span 10;
      }

      @media (min-width: $break-md) {
        grid-column: 3 / span 9;
      }
    }
  }

  //  Grandparent of .alert
  div:has(> div > .alert) {
    margin: 0 0 0 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 1rem !important;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    //  parent of .alert
    div:has(> .alert) {
      padding: unset;
      grid-column: 1 / span 12;

      @media (min-width: $break-lg) {
        grid-column: 1 / span 12;
      }
      @media (min-width: $break-xl) {
        grid-column: 2 / span 10;
      }
      @media (min-width: $break-2xl) {
        grid-column: 3 / span 8;
      }

      .alert {
        margin-bottom: unset;
        margin-top: 0;
        background: $transparent-dark-orange;
        border: none;
        cursor: default;
        color: white;
        border-radius: var(--border-radious-md, 0.75rem);
      }
    }
  }
}
